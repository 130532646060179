import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // Perform logout logic
    sessionStorage.removeItem('token') // or sessionStorage.clear() if using session
    sessionStorage.removeItem('reduxState') // Clear the persisted state

    // Show logout success message
    Swal.fire({
      title: 'Logged out successfully!',
      icon: 'success',
      confirmButtonText: 'Ok',
    })

    // Redirect to the login page after logout
    navigate('/login')
  }, [navigate])

  return <div>Logging out...</div>
}

export default Logout
