import { legacy_createStore as createStore } from 'redux'

// Load state from sessionStorage
const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('reduxState')
    return serializedState ? JSON.parse(serializedState) : undefined
  } catch (err) {
    console.error('Failed to load state from sessionStorage:', err)
    return undefined
  }
}

// Save state to sessionStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      user: state.user, // Only persist the user state
    })
    sessionStorage.setItem('reduxState', serializedState)
  } catch (err) {
    console.error('Failed to save state to sessionStorage:', err)
  }
}

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'dark',
  // user: {
  //   // Default user data
  //   name: 'Guest',
  //   role: 'User',
  // },
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'SET_USER':
      return { ...state, user: rest.user } // Update user data
    case 'LOGOUT':
      return {
        ...state,
        user: initialState.user, // Reset user state to initial value
      }
    default:
      return state
  }
}

// Load persisted state into the store
const persistedState = loadState()

const store = createStore(changeState, persistedState)

// Subscribe to store changes to save state
store.subscribe(() => {
  saveState(store.getState())
})

export default store
