import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { addSessionTime, isTokenValid } from 'src/util/auth'
import Swal from 'sweetalert2'

const IdleSessionTimeout = ({ children }) => {
  const navigate = useNavigate()
  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll']
    events.forEach((event) => {
      document.addEventListener(event, addSessionTime)
    })

    const intervalId = setInterval(() => {
      if (!isTokenValid()) {
        Swal.fire({
          icon: 'warning',
          title: 'Session Timeout',
          text: 'Your session has expired. Please login again.',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/')
        })
      }
    }, 1000)

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, addSessionTime)
      })
      clearInterval(intervalId)
    }
  }, [navigate])
  return <>{children}</>
}

IdleSessionTimeout.propTypes = {
  children: PropTypes.node.isRequired, // Ensure 'children' is passed and is of type 'node'
}
export default IdleSessionTimeout
