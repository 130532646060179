import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import Swal from 'sweetalert2'
import { addSessionToken, isTokenValid } from 'src/util/auth'
import SHA512 from 'crypto-js/sha512'

const apiUrl = process.env.REACT_APP_API_BASE_URL

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isTokenValid()) {
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }, [navigate])

  const handleLogin = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const salt =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    const saltedPassword = password + salt
    const hashedPassword = SHA512(saltedPassword).toString()

    console.log('Salt:', salt)
    console.log('Hashed password with salt:', hashedPassword)

    const data = {
      Email: email,
      Password: password,
      Salt: salt,
    }

    try {
      const response = await axios.post(`${apiUrl}/api/Account/Login`, data)

      if (response?.data?.IsSuccess && response?.data?.Response?.Token) {
        const token = response.data.Response.Token
        addSessionToken(token)

        const user = {
          name: `${response.data.Response.FirstName} ${response.data.Response.LastName}`,
          role: response.data.Response.RoleName || 'User',
        }

        dispatch({
          type: 'SET_USER',
          user: user,
        })

        // await Swal.fire({
        //   icon: 'success',
        //   title: 'Login Successful',
        //   text: 'You have been logged in successfully.',
        //   timer: 2000,
        //   showConfirmButton: false,
        // })

        navigate('/dashboard')
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: 'Login failed. Please check email or password.',
        })
      }
    } catch (error) {
      console.error('Login error:', error)

      if (error.response) {
        await Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: error.response.data?.Message || 'Login failed. Please check your credentials.',
        })
      } else if (error.request) {
        await Swal.fire({
          icon: 'error',
          title: 'Network Error',
          text: 'No response from server. Please check your network connection and try again.',
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again later.',
        })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center loginPage">
      <CContainer fluid>
        <CRow className="justify-content-center">
          <CCol xs={11} md={6} lg={4} xl={4} xxl={3} className="loginFrom">
            <CForm onSubmit={handleLogin}>
              <h3 className="mb-0 text-center">Admin Login</h3>
              <p className="text-center mb-3">Sign In to your account</p>
              <CInputGroup className="mb-3">
                <CInputGroupText>
                  <CIcon icon={cilUser} />
                </CInputGroupText>
                <CFormInput
                  placeholder="Email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText>
                  <CIcon icon={cilLockLocked} />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </CInputGroup>
              <CRow className="mb-3">
                <CCol>
                  <CButton className="w-100 login_primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <CSpinner size="sm" /> : 'Login'}
                  </CButton>
                </CCol>
                <CCol>
                  <CButton
                    color="link"
                    className="w-100 text-decoration-none"
                    onClick={() => navigate('/forgetpassword')}
                  >
                    Forgot password?
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
